import React from "react";

interface Props {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  full?: boolean;
  style?: React.CSSProperties;
}

const Section = ({ id, children, className, full = false, style, ...rest }: Props) => (
  <section
    {...rest}
    id={id}
    className={"px-4 py-8 lg:px-20 lg:py-32 " + className}
    style={style}
  >
    { full
        ? (<div className="max-w-screen-xl mx-auto w-full">{children}</div>)
        : (<>{children} </>)
    }
  </section>
);

export default Section;
