import * as React from "react"
import { Link } from "gatsby"
import TitleIntro from "components/sections/titleIntro";
import BaseButton from "components/button/BaseButton";
import {useEffect} from "react";

const NotFoundPage = () => {

  useEffect(() => {
    if ("plausible" in window) {
      window.plausible('404', { props: { path: document.location.pathname } });
    }
  }, []);

  return <>
    <TitleIntro title="Oups !" />
    <section className="container mb-16 lg:mb-28 lg:px-52 flex flex-col items-center">
        <img src="/assets/404.gif" alt="image 404" className="mb-5"/>
        <p className="text-b1 mb-5">Cette page n’existe pas.</p>
        <BaseButton tag={Link} to="/" size="md" variant="light">Retour</BaseButton>
    </section>
    </>
}

export default NotFoundPage
